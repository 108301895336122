.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  visibility: visible;
  transition: 250ms;
  transform: scale(1);

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.backdrop.is-hidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: scale(2);
}

.modal {
  display: flex;

  position: absolute;
  top: 50%;
  left: 50%;


  background-color: #151515;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  transform: translate(-50%, -50%) scale(1);
  z-index: 9999;

  @media screen and (max-width:767px) {
    top:40%
  }
  
}

@media screen and (min-width: 1200px) {
  .modal{
  max-width: 728px;
  height: max-content;
}
}

@media screen and (max-width: 999px) {
  .modal{
  max-width: 450px;
  height: max-content;
  width: 100%;
  }

  @media screen and (min-width: 1000px) and (max-width:1199px) {
    .modal{
    max-width: 650px;
    }
  }

}

.modal__close {
  display: inline-flex;
  transition: 250ms;

  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  background-color: #000;
  border: 0;
  color: #fff;
  cursor: pointer;

}

.modal__title {

  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.03em;
  font-weight: 600;

  @media screen and (min-width: 1000px) {
    line-height: 1.15;
    margin-bottom: 69px;
  }
}

/* Form field */

.form{
  width: 50%;
  display: grid;
  padding: 36px;


  @media screen and (max-width:1000px) {
    width: 100%;
    gap: 8px;
  }
}

.form__field {
  margin-bottom: 10px;
}

.form__label {
  display: block;
  margin-bottom: 4px;

  font-size: 18px;
  line-height: 1.16;
  letter-spacing: 0.01em;
}

.form__inner {
  position: relative;
}

.form__input {
  resize: none;
  padding: 12px 42px 12px;

  width: 100%;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 4px;

  font-size: 12px;
  line-height: 1.16;
  letter-spacing: 0.01em;
  outline: transparent;

  
}

.form__input:hover,
  .form__input:hover.textarea {
    border: 1px solid #89bf19;
    border-radius: 4px;
    fill: #89bf19;
  }

  .form__input:focus + .icon-modal {
    fill: #89bf19;
  }

.form__input.textarea {
  resize: none;
  padding: 12px 16px 12px;

  width: 100%;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 4px;

  font-size: 12px;
  line-height: 1.16;
  letter-spacing: 0.01em;
}

.form__icon {
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  fill: #89bf19;
  transition: 250ms;
}

.modal__button {
  z-index: 9999;
}

.modal__button:disabled{
  background:#50b03a70
}

.modal__list-item{
  display: flex;
  gap: 8px;
}

.checkbox {
  font-size: 16px;
}

.modal__img{
  object-fit: none;
}

.error__phone {
  display: none;
  font-size: 12px;
  color: red;
  margin-top: 5px;
}

.error__mail {
  display: none;
  font-size: 12px;
  color: red;
}

.formRectangle{
  display: none;

  @media screen and (min-width:1000px) {
    display: block;
    width: 50%;
    object-fit: unset;
  }
}