.platform{
    margin-bottom: 50px;
}

.platform__descr-title span{
    color: #89bf19;
}

.platform__list{
    display: grid;
    gap: 15px;
    color: #D2D2D2;
    @media screen and (min-width:1000px){
        width: 50vw;
    }
}

.platform__list-wrapper{
    align-items: flex-start;

    gap: 60px;
    margin-bottom: 120px;
    padding-top: 46px;

    @media screen and (min-width:1000px){
        display: flex;
    }
}

.platform__list-item{
    display: flex;
    gap: 10px;
}

.platform__list-itemImg{
    object-fit: none;
}

.platform__img{
    padding: 15px;
    background-color: #89BF19;
    border-radius: 50px;

    text-align: center;
    position: relative;

    @media screen and (min-width:1000px) and (max-width:1399px) {
        height: 1000px;
        border-radius: 200px;
        padding: 35px;
    }

    @media screen and (min-width:1400px) {
        height: 1300px;
        border-radius: 200px;
        padding: 35px;
    }
}


.platform__img-descr{
 text-align: start;
    @media screen and (min-width:1000px){
        max-width: 923px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    }
}


.mockup__img{
    position: absolute;
    left: 100px;

    @media screen and (max-width:1000px) {
        position: unset;
    }
}

.platform__img-title{
    font-size: 40;
    line-height: 1;

    color: #000;
    margin-bottom: 30px;
    margin-top: 40px;

    @media screen and (min-width:1000px){
        font-size: 60px;
    line-height: 0.88;
    margin-top: 30px;
    }
}

.platform__img-descr{
    color: #000;
    
}

.platform__benefits-list{
    display: none;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 50px;

    @media screen and (min-width:1000px) {
        margin-top: 300px;
        display: flex;
    }
}

.platform__benefits-list-item{
    display: grid;
    justify-content: center;
    justify-items: center;

    width: 200px;

    @media screen and (min-width:1000px) {
        height: 320px;

    }
}

.swiper{
    margin-bottom: 50px;

    @media screen and (min-width:1000px) {
        display: none;
      }
}



.slider-wrapper{
    max-height: 450px;
    margin-left: 10px;
    border-radius: 40px;
    text-align: center;
    margin-top: auto;
    padding: 20px;
}

.swiper-img{
    max-width: 150px;
    height: 200px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active{
    background: #89bf19;
}

span.swiper-pagination-bullet{
    background: #fff;
}