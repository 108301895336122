.section{
    padding: 58px 160px;

    @media screen and (max-width:1000px) {
      padding: 50px 30px
    }
}

button {
    border-radius: 50px;
    border: 1px solid transparent;
    line-height: 1.15;
    width: 200px;
    height: 55px;
    font-size: 20px;
    font-weight: 400;
    font-family: inherit;
    background-color: #89BF19;
    cursor: pointer;
    transition: border-color 0.25s;
    color: #000;
  }
  button:hover {
    border-color: #646cff;
  }
  button:focus,
  button:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
  }