@import './normalize';
@import './elements';
@import './header';
@import './hero';
@import './benefits';
@import '/node_modules/modern-normalize/modern-normalize.css';
@import './benefitsSteps';
@import './platform';
@import './investplan';
@import './footer';
@import './modal';
@import './telInput';
@import './thankYou';
@import './mobile-menu';
