.hero{
    background-size: contain;
    background-image: url('../assets/heroBackground.png');

    @media screen and (max-width:1000px) {
    background-image: url('../assets/heroMobileBackground.png');
    background-size: cover;
    display: block;
    }
}

.hero__desktop{
    display: none;

    @media screen and (min-width:1000px) and (max-width: 1200px) {
        display: flex;
        flex-wrap: wrap;
    }

    @media screen and (min-width:1201px)  {
        display: flex;
        flex-wrap: nowrap;
    }

}

.hero__mobile{
    @media screen and (min-width:1000px) {
        display: none;
    }
}

.hero__descr-wrap{
    width: 33vw;
}

.hero__list-title{
    font-size: 24px;
    font-weight: 700;
}

.main__title{
    font-size: 72px;
    line-height: 0.83;

    font-weight: 700;
    margin: 0;
    color: #89BF19;
    margin-bottom: 44px;

    @media screen and (min-width:1000px) {
        font-size: 100px;
        line-height: 0.85;

    }
}

.hero__list{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.hero__list-item{
    display: flex;
    gap: 15px;
    width: max-content;
    padding: 13px;
    background: linear-gradient(180deg, #1D1F29 0%, #191A21 100%), linear-gradient(90.08deg, #1C1C1C 0.07%, #151515 99.94%);
}

.mainImg__wrapper{
width: 33vw;
}

.mainImg{
    width: 100%;
    @media screen and (min-width:768px) and (max-width:1000px) {
        top: -40px;
        position: absolute;
        height: 504px;
        position: absolute;
        right: 80px;
        width: 220px;
    }

    @media screen and (max-width:767px){
        position: absolute;
        height: 504px;
        position: absolute;
        top: -165px;
        right: 80px;
        width: 220px;
    }
}


.lectorDescr__container{
    margin-top: auto;
    width: 33vw;
    margin-left: 30px;

    @media screen and (min-width:1001px) and (max-width: 1200px) {
        width: 100%;
    }
}

.portraitImg{
    object-fit: cover;
    border-radius: 100px;
    width: 124px;
    height: 124px;
    margin-bottom: 20px;
}

.hero__mobile-wrapper{
    position: relative;
    z-index: 2;
}
