.mobile__menu.is-open {
    height: 100vh;
}

.mobile__navigation{
    display: none;
}

.mobile__navigation.is-open{
    display: block;
}

.mobile__menu .navigation__opened {
    display: none;
}

.mobile__menu.is-open .navigation__opened {
    display: block;
}

.mobile__menu.is-open .navigation__closed {
    display: none;
}

.mobile__navigation {
    background-color: #0D0E13;
    width: 100%;
    height: 100%;
}

.mobile__navigation-list {
    height: 400px;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
}

.mobile__navigation-item{
    color: rgba(255, 255, 255, 1);
    font-size: 30px;
    line-height: 1.23;
    padding-bottom: 30px;
}

.mobile__navigation-item:not(:last-child){
    border-bottom: 1px solid #54546B;

}

.header__links.mobile {
    display: flex;
    justify-content: center;
    align-items: center;
}


.header__links-item.openButton.mobile {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    margin-bottom: 16px;
    width: 100%;
    color: #fff;
    justify-content: center;
    background: linear-gradient(180deg, #50B03A 0%, #3A9126 100%), #3A9126;
}

.header__links-item.mobile {
    background: #4B4B5C;
    width: 144px;
}

.navigation__closed{
    width: 20px;
    height: 13px;
}

.mobile__menu{
    background-color: #000;
}

.mobile__menu-container {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
    height: 64px;
    padding: 0 10px;
    border-bottom:1px solid #89bf19;

    @media screen and (min-width: 1001px) {
          display: none;
    }
}

.mobile__button {
    background-color: transparent;
    border: 0px;
    max-width: max-content;
}

.mobileButton {
    width: 150px;
    background: linear-gradient(180deg, #50B03A 0%, #3A9126 100%), #3A9126;
    height: 40px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border: 0;
}

.mobileIcon{
    width: 44px;
    height: 44px;
    background: #4b4b5c;
    border-radius: 40px;
    border: 1px solid #fff;
    justify-content: center;
    align-items: center;

    font-size: 14px;
}

.mobileIcon:hover{
    border: 1px solid #89bf19;
}
