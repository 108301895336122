header{
   position: sticky;
   top: 0;
   z-index: 99; 
}

.header__container {

    display: flex;
    background-color: #000;
    align-items: center;
    justify-content: space-around;
    height: 73px;

    @media screen and (max-width: 1000px)  {
        display: none;
    }

    @media screen and (min-width:1000px) and (max-width:1199px) {
        padding: 0 20px;
    }

    // @media screen and (min-width:1200px) {
    //     padding: 0 160px;
    // }
}

.mobButton{
    @media screen and (max-width:470px) {
        width: 130px;
    }
}

.logo {
    height: 49px;
    width: 169px;
    object-fit: contain;
    @media screen and (max-width:1000px) {
        height: 42px;
        width: 135px;
       
    }
}

.header__navigation-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 33px;
    color: #fff;
    margin-bottom: 30px;

    @media screen and (min-width:1001px) {
    justify-content: center;
    margin: 0;
    }
}

.header__navigation-item:hover{
    text-decoration: underline;
}

.header__links-list {
    display: flex;
    align-items: center;
    gap: 15px;
}

.header__links-icon{
    display: flex;
    gap: 9px;
    color: #fff;
    font-size: 14px;
    width: 100px;
    height: 44px;
    justify-content: center;
    align-items: center;
}


.header__links-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    
    background-color: #313137;
    border-radius: 25px;
}

.header__links-item:hover{
    background-color: #4B4B5C;
}




.button.header__button{
    width: 150px;
    font-size: 14px;
    margin: 0;
}

.button.header__button::before{
    margin: 0;
}
.button.header__button.demo{
    display: block;
    color: #89bf19;
    background: #111111;
    margin-left: auto;
    margin-right: auto;
    width: 139px;
}



.main__image-mobile {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.header__learn {
    display: flex;
    align-items: center;

    font-size: 14px;
    height: 44px;
    border:0;
    color: #fff;
    background: linear-gradient(#50b03a 0%, #3a9126 100%), #3a9126;
    cursor: pointer;
}



.header__learn:hover{
    background-color: #4B4B5C;

}

.header__learn.mobile {
    width: 150px;
}

.language-icon{
    display: flex;
    color: #89bf19;
}

.icon-inactive.is-hidden{
     display: none;
      pointer-events: none;
      visibility: hidden;
    }



