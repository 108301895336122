.benefitsImg__list{
    display: grid;
    gap: 28px;
    margin-bottom: 67px;
    justify-content: center;

    @media screen and (min-width:900px) {
        grid-auto-flow: column;
    }
}

.benefitsImg__list-item.styled{
    padding-top: 100px;
}

.benefits__title{
    color: #89BF19;
}

.benefitsMain__wrapper{
    @media screen and (min-width:1200px) {
        display: flex;
    }
}

.benefitsMain__subwrapper-title{
    @media screen and (min-width:1201px) {
        width: 40vw;
        margin-right: 50px;
        padding-bottom: 120px;
    }
}

.benefitsMain__subwrapper-list{

    @media screen and (min-width:1201px) {
     width: 50vw;
    height: max-content;
    padding: 31px 44px;

    border: 2px solid #1D1F29;
    border-radius: 45px;
    }
}





