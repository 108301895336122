.footer{
    background-color: black;
    padding: 50px 160px;
    color: #fff;

    @media screen and (max-width:1201px) {
        padding: 30px 30px;
    }
}

.footer__logo{
    width: 240px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
}

.footer__container{
    @media screen and (min-width:1201px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
}
}

.footer_descr{
    font-size: 12px;
    margin-top: 20px;
    text-align: center;

    @media screen and (min-width:1201px) {
        max-width: 400px;
        margin: 0;
        text-align: justify;
    }
}

.footer__nav-title{
    text-align: center;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    margin-top: 20px;

    @media screen and (min-width:1201px) {
        margin: 0;
    }
}

.footer__nav-div{
display: grid;
gap:10px
}

.demo__button{
    @media screen and (min-width:1000px) {
        display: none;
    }
    display: flex;
    position: sticky;
    bottom: 0;

    background-color: #89bf19;
    width: 100%;
    padding: 20px;
    z-index: 100;
}

.header__button.demo.bottom{
    background-color: black;
    font-size: 18px;
    width: 200px;
}

.footer__navigation-list{
    text-align: center;
    font-size: 32px;

    @media screen and (min-width:1200px) {
        width: 312px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        grid-gap: 5px;
        margin: 0;
        font-size: 20px;
    }
}